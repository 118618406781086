import deegayu from "./deegayu-dissanayake.png";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";

import portfolioImage1 from "./deegayu-profile-1.jpg";
import portfolioImage2 from "./deegayu-profile-2.jpg";
import portfolioImage3 from "./deegayu-profile-3.jpg";
import portfolioImage4 from "./deegayu-profile-4.jpg";
import portfolioImage5 from "./deegayu-profile-5.jpg";
import portfolioImage6 from "./deegayu-profile-6.jpg";
import portfolioImage7 from "./deegayu-profile-7.jpg";
import portfolioImage8 from "./deegayu-profile-8.jpg";
import portfolioImage9 from "./deegayu-profile-9.jpg";

function App() {
  return (
    <div className="App">
      <Container fluid="md" className="profile-intro-container">
        <Row className="profile-intro-block">
          <Col md={{ span: 8, offset: 4 }}>
            <div className="profile-description">
              <div className="title">
                <img src={deegayu} alt="Deegayu Dissanayake" />
              </div>
              <div className="title-header">CINEMATOGRAPHER / PRODUCER</div>
              <div className="sub-header">
                BRIDGING CULTURES THROUGH CINEMATOGRAPHY
              </div>
              <div className="intro">
                Deegayu Dissanayaka emerges as a pivotal figure in connecting
                the vibrant narratives of Japan and Sri Lanka. As a
                cinematographer with deep roots in both countries, Deegayu
                embodies the essence of cross-cultural collaboration, bringing
                together diverse cinematic traditions and practices. His work is
                a testament to the power of film as a universal language,
                capable of weaving together threads from different cultures into
                a cohesive narrative tapestry.
                <br></br>
                <br></br>
                Within the dynamic framework of Ceyloncine Productions, Deegayu
                plays a crucial role, ensuring that the unique needs of
                international film teams are not just met but exceeded, with a
                deep understanding and respect for the art of filmmaking.
                <br></br>
                <br></br>
                One of Deegayu’s key talents lies in his ability to coordinate
                productions, ensuring that every aspect of the filming process
                is aligned with the vision of the project. His comprehensive
                approach to filmmaking encompasses not just the technical
                requirements of cinematography but also the broader production
                needs. This includes evervthina from securina the riaht
                locations to understandina the specific aesthetic and narrative
                aoals of each proiect. in Ceyloncine Productions, he is
                instrumental in making sure that these production needs are
                meticulouslv planned and executed, guaranteeing that the final
                product reflects the highest standards of
              </div>
            </div>
          </Col>
        </Row>

        <Row className="scroll-animation">
          <Col>
            <div className="mouse" id="scroll-ico"></div>
          </Col>
        </Row>

        <Row className="portfolio">
          <Col>
            <Row className="portfolio-item">
              <Col md={{ span: 4 }}>
                <div className="port-title">Freelance Engagements</div>
                <div className="port-sub-title">(2015-2018)</div>
              </Col>
              <Col md={{ span: 8 }} className="port-desc">
                Within this span, I had the honor of collaborating with esteemed
                private enterprises, contributing my cinematographic finesse to
                an array of projects. My tenure as a freelance professional not
                only enabled me to embrace multifaceted roles but also enriched
                my perspectives, further elevating my craft.
              </Col>
            </Row>

            <Row className="portfolio-item">
              <Col md={{ span: 4 }}>
                <div className="port-title">Personal Endeavors</div>
              </Col>
              <Col md={{ span: 8 }} className="port-desc">
                Beyond the precincts of professional obligations, I have
                consistently nurtured my creativity through a series of personal
                projects that seamlessly push the boundaries of artistic
                expression. These undertakings bear testimony to my unwavering
                dedication to innovation and the exploration of narratives.
              </Col>
            </Row>

            <Row className="portfolio-item">
              <Col md={{ span: 4 }}>
                <div className="port-title">NGO Creative Youth, Sri Lanka</div>
                <div className="port-sub-title">(2011-2017)</div>
              </Col>
              <Col md={{ span: 8 }} className="port-desc">
                In an instrumental capacity as an editor and creative luminary,
                I channeled my skills toward the Creative Youth NGO in Sri
                Lanka. This chapter of my journey not only served to refine my
                storytelling acumen but also enabled me to make a substantive
                contribution to initiatives of consequence.
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="portfolio-images">
          <Col>
            <img src={portfolioImage1} alt="portfolioImage1" />
            <img src={portfolioImage2} alt="portfolioImage2" />
            <img src={portfolioImage3} alt="portfolioImage3" />
            <img src={portfolioImage4} alt="portfolioImage4" />
          </Col>
        </Row>

        <Row className="portfolio">
          <Col>
            <Row className="portfolio-item">
              <Col md={{ span: 4 }}>
                <div className="port-title">Artistic Paradigm</div>
              </Col>
              <Col md={{ span: 8 }} className="port-desc">
                My work embodies a distinctive embodiment of life’s intricate
                nuances and emotions, deftly captured through the lens and
                presented with unparalleled perspective. Each frame encapsulates
                a concerted effort to evoke a gamut of emotions, engrossing
                audiences within intricate visual tales that pulsate with life.
              </Col>
            </Row>

            <Row className="portfolio-item">
              <Col md={{ span: 4 }}>
                <div className="port-title">Visual Identity</div>
              </Col>
              <Col md={{ span: 8 }} className="port-desc">
                At the heart of my artistic ethos lies a fervent affinity for
                dynamic compositions that convey stories beyond the spoken word.
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="portfolio-images">
          <Col>
            <img src={portfolioImage5} alt="portfolioImage5" />
            <img src={portfolioImage6} alt="portfolioImage6" />
            <img src={portfolioImage7} alt="portfolioImage7" />
            <img src={portfolioImage8} alt="portfolioImage8" />
            <img src={portfolioImage9} alt="portfolioImage9" />
          </Col>
        </Row>

        <Row className="bottom-desc">
          <div>
            Drawing inspiration from my dual abode in Tokyo and Sri Lanka, my
            work becomes the synthesis of these two cultural dimensions. This
            amalgamation finds expression in my ability to seamlessly integrate
            diverse elements, engendering visuals that resonate universally
            while retaining an undeniably authentic essence. While I observe the
            utmost discretion for projects held under confidentiality due to
            considerations of privacy and copyright, it is my privilege to
            present an exclusive selection of highlights that underscore my
            prowess in cinematography. These meticulously curated samples
            provide a glimpse into my artistic versatility, technical finesse,
            and unyielding commitment to excellence. I extend my heartfelt
            gratitude for navigating through my cinematography portfolio. It is
            my fervent aspiration that this compilation conveys not only my
            passion and growth but also my resolute commitment to the art of
            visual storytelling. I eagerly anticipate the prospect of potential
            collaboration, where together, we can breathe life into captivating
            narratives.
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default App;
